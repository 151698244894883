import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/UI/Layout"
import Navbar from "../../components/UI/Navbar"
import Seo from "../../components/Seo"
import ServicePage from "../../components/templates/ServicePage"

const query = graphql`
  {
    allContentfulInstallationImages(
      filter: {
        metadata: {
          tags: { elemMatch: { contentful_id: { eq: "pondlessWaterfalls" } } }
        }
      }
    ) {
      nodes {
        id
        image {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
          id
          title
        }
      }
    }
  }
`


export default function PondlessWaterfallsPage() {
  const title = `Pondless Waterfall` // Singular (not plural)
    const {
      allContentfulInstallationImages: { nodes: image },
    } = useStaticQuery(query)

    const imageList = image[0].image

  return (
    <Layout>
      <Navbar />
      <Seo
        title={`${title}s`}
        description={`A ${title} can fit in almost any size yard. We can help build & install the relaxing sound of water to transform your property.`}
      />

      <ServicePage
        title={title}
        imageList={imageList}
        img={
          <StaticImage
            src="../../assets/images/Natural-Style-Disappearing-Waterfall_Andreatta-Waterscapes_Ashland-Oregon_compress.jpg"
            quality={90}
            loading="eager"
            style={{
              position: "absolute",
              width: "100%",
              zIndex: 1,
              height: "21rem",
            }}
            objectFit="cover"
            objectPosition="50% 50%"
            alt="Pondless Waterfall Medford Oregon"
          />
        }
        tagline={`The sight and sound of water`}
        body={[
          {
            id: 0,
            type: "paragraph",
            content: `While some waterfalls will splash down into a pond, others will end at a gravel basin with no pool... we call these water features Pondless Waterfalls or Disappearing Waterfalls. The Pondless waterfall basin is under gravel so there is no visible body of water (thereby making it… Pondless!)`,
          },
          {
            id: 1,
            type: "paragraph",
            content: `A Pondless waterfall design can fit in almost any size yard. Children love to play in the water, and without the pond basin, a Pondless waterfall provides a safe alternative for them to explore nature and get their feet wet. Just as with a pond or fountain, a Pondless waterfall will attract wildlife such as dragonflies, hummingbirds and butterflies and are a joy to watch.`,
          },
          {
            id: 2,
            type: "paragraph",
            content: `This popular type of water feature brings the relaxing sight and sound of water to your back or front yard and has the added benefit of being extremely low maintenance and energy efficient. `,
          },
        ]}
      />
    </Layout>
  )
}
